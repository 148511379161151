import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from "gatsby-image";

// stateless function component
//https://react-slick.neostack.com/
//https://github.com/akiran/react-slick
const SuitesGallery = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,

    slidesToShow: 1,
    slidesToScroll: 1,

    initialSlide: 0,
    focusOnSelect: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,

          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  var lightboxSettings = {
    dots: true,
    infinite: true,
    speed: 300,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const [active, setActive] = useState("");
  //const [currentSlide, setCurrentSlide] = useState(3);

  const handleClick = (event) => {
    //console.log("slider:" + slider1);
    setActive("active");
  };
  const handleClose = (event) => {
    if ((event.target.tagName !== "BUTTON") & (event.target.tagName !== "IMG")) {
      setActive("");
    }
  };
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef();
  const slider2 = useRef();

  const { allWordpressWpMedia } = useStaticQuery(
    graphql`
      query SuitesGalleryQuery {
        allWordpressWpMedia {
          edges {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 985) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              caption
              alt_text
            }
          }
        }
      }
    `
  );

  return (
    <div className="gallery__wrapper suites__gallery__wrapper">
      <a id="gallery"></a>
      <div className="container">
        <h2 className="section__title">{props.title}</h2>
        <div className={`over-dark-gallery ${active}`} onClick={handleClose}>
          <div className="gallery__close">
            <i className="gallery__icon__close"></i>
          </div>
          <div className={`gallery__lightbox ${active}`}>
            <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...lightboxSettings}>
              {allWordpressWpMedia.edges.map(({ node }) => {
                if (node.alt_text === props.suite && node.localFile) {
                  return (
                    <div className="slider__key" key={node.id}>
                      {node.localFile && (
                        <Img
                          className="slider__image"
                          fluid={node.localFile.childImageSharp.fluid}
                        />
                      )}
                    </div>
                  );
                } else {
                  console.log("found no gallery");
                }
              })}
            </Slider>
          </div>
        </div>

        <Slider
          asNavFor={nav1}
          ref={(slider2) => setNav2(slider2)}
          {...settings}
          className="gallery__main"
        >
          {allWordpressWpMedia.edges.map(({ node }) => {
            if (node.alt_text === props.suite && node.localFile) {
              return (
                <div className="slider__key" key={node.id} onClick={handleClick}>
                  {node.localFile && (
                    <Img className="slider__image" fluid={node.localFile.childImageSharp.fluid} />
                  )}
                </div>
              );
            }
          })}
        </Slider>
      </div>
    </div>
  );
};

export default SuitesGallery;
