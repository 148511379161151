import React, { useState } from "react";
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from "gatsby-image";
import ContactForm from "./ContactForm";

// stateless function component
//https://react-slick.neostack.com/
//https://github.com/akiran/react-slick
const SimpleSlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 9500,
    pauseOnHover: false,
    beforeChange: (next, current) => (document.body.className = "loaded slide-change"),

    afterChange: () => (document.body.className = "loaded"),
  };
  /*  
allWordpressPost(sort: { order: ASC, fields: acf___order }) {
*/
  const { allWordpressPost } = useStaticQuery(
    graphql`
      query MyQuery {
        allWordpressPost(sort: { order: ASC, fields: acf___order }) {
          edges {
            node {
              title
              content
              categories {
                name
              }
              featured_media {
                alt_text
                link
                source_url
                localFile {
                  childImageSharp {
                    fixed(width: 125, height: 125) {
                      ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 2500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const handleButtonClick = () => {
    setPopupStatus("active");
  };

  const [PopupStatus, setPopupStatus] = useState("hidden");

  const handlePopupClose = (event) => {
    if (event.target.className === "mfp-container" || event.target.className === "mfp-close") {
      setPopupStatus("hidden");
    }
    //setPopupStatus("hidden");
    //console.log(event.target.className);
  };

  return (
    <React.Fragment>
      <ContactForm status={PopupStatus} onPopupClose={handlePopupClose} />
      <Slider {...settings}>
        {allWordpressPost.edges.map(({ node }) => {
          if (node.categories[0].name === "Slider Item") {
            return (
              <div className="slider__key" key={node.id}>
                <div className="activeWrap">
                  {node.featured_media ? (
                    <Img
                      className="slider__image"
                      fluid={node.featured_media.localFile.childImageSharp.fluid}
                    />
                  ) : null}
                  <div className="kl-slide-overlay"></div>
                  <div className="slider__captions container">
                    <h2
                      className="caption__title"
                      dangerouslySetInnerHTML={{ __html: node.title }}
                    />

                    <h3
                      className="caption__description "
                      dangerouslySetInnerHTML={{
                        __html: node.content,
                      }}
                    ></h3>
                    <button className="slider__button">
                      <a className="button" onClick={handleButtonClick}>
                        Take a Tour
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </Slider>
    </React.Fragment>
  );
};

export default SimpleSlider;
