import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";
import { renderStatic } from "react-helmet";

const Section = props => {
  const { allWordpressPost } = useStaticQuery(
    graphql`
      query SectionQuery {
        allWordpressPost {
          edges {
            node {
              title
              content
              categories {
                name
              }
              featured_media {
                alt_text
                link
                source_url
                localFile {
                  childImageSharp {
                    fixed(width: 125, height: 125) {
                      ...GatsbyImageSharpFixed
                    }
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <section className={props.layout}>
      <div className="container">
        {allWordpressPost.edges.map(({ node }) => {
          if (
            node.categories[0].name === "Section" &&
            node.title.toLowerCase() === props.title.toLowerCase()
          ) {
            // Split title into seperate elements.
            const splitString = node.title.split(" ");
            return (
              <div className="section__wrapper" key={node.id}>
                <div className="section__title__block">
                  <h3 className="section__title">
                    {splitString.map((item, index) => {
                      return <span key={index}>{item} </span>;
                    })}
                    <span className="tbk__symbol ">
                      <span></span>
                    </span>
                  </h3>
                </div>
                <div className="section__content--flex">
                  {node.featured_media && (
                    <div className="section__image">
                      <Img alt="" fluid={node.featured_media.localFile.childImageSharp.fluid} />
                    </div>
                  )}
                  <div className="section__content">
                    <p
                      className="section__content"
                      dangerouslySetInnerHTML={{ __html: node.content }}
                    ></p>
                  </div>

                  {node.acf.action_button && (
                    <div className="section__col">
                      <button className="button">{node.acf.action_button}</button>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className="kl-mask kl-bottommask kl-mask--shadow_simple_down kl-mask--light"></div>
    </section>
  );
};

export default Section;
