import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";
import { renderStatic } from "react-helmet";
import ContactForm from "./ContactForm";
import SuitesGallery from "./SuitesGallery";

const Suites = (props) => {
  const [PopupStatus, setPopupStatus] = useState("hidden");
  const handlePopupClick = () => setPopupStatus("active");
  const handlePopupClose = (event) => {
    if (event.target.className === "mfp-container" || event.target.className === "mfp-close") {
      setPopupStatus("hidden");
    }
    //setPopupStatus("hidden");
    //console.log(event.target.className);
  };
  const { allWordpressPost } = useStaticQuery(
    graphql`
      query SuitesQuery {
        allWordpressPost {
          edges {
            node {
              title
              content
              categories {
                name
              }
              acf {
                size
                category
                amenities
                price
              }
              featured_media {
                alt_text
                link
                source_url
                localFile {
                  childImageSharp {
                    fixed(width: 125, height: 125) {
                      ...GatsbyImageSharpFixed
                    }
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <section className={props.layout}>
      <a id="suites"></a>
      <ContactForm status={PopupStatus} onPopupClose={handlePopupClose} />
      <div className="container suites--container">
        {allWordpressPost.edges.map(({ node }) => {
          if (node.categories[0].name === "Suite") {
            return (
              <div className="section__wrapper" key={node.id}>
                <div className="section__title__block"></div>
                <div className="section__content--flex">
                  {node.featured_media && (
                    <div className="section__image">
                      {/*   <Img alt="" fluid={node.featured_media.localFile.childImageSharp.fluid} /> */}

                      <SuitesGallery suite={node.title} />
                    </div>
                  )}
                  <div className="section__content">
                    <h3
                      className="section__title"
                      dangerouslySetInnerHTML={{ __html: node.title }}
                    ></h3>
                    <div className="section__content--inner">
                      <div
                        className="section__content--dynamic"
                        dangerouslySetInnerHTML={{ __html: node.content }}
                      ></div>
                      {node.acf.size && (
                        <div className="suites__propertyInfo">
                          Total Area : {node.acf.size} Square Feet
                        </div>
                      )}
                      {node.acf.category && (
                        <div className="suites__propertyInfo">Category : {node.acf.category}</div>
                      )}
                      {node.acf.amenities && (
                        <div className="suites__propertyInfo">Amenities : {node.acf.amenities}</div>
                      )}
                      {node.acf.price && (
                        <div className="suites__propertyInfo">Price : {node.acf.price}</div>
                      )}
                      <button className="button">
                        <a onClick={handlePopupClick}>Get Details</a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </section>
  );
};

export default Suites;
